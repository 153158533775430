import * as React from "react"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Header from "../components/Header"
import HeroSection from "../components/Hero"
import ServicesComponent from "../components/ServiceComponent"

const IndexPage = () => {
  return (
    <>
    <Header />
    <HeroSection />
    <ServicesComponent />
    <ContactForm />
    <Footer />
  </>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
