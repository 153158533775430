import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";



const Navigation = () => (

  <header>

    <a href="#" class="logo">M<span>C</span>S</a>

    <input type="checkbox" id="menu-bar" />
    <label for="menu-bar" class="fas fa-bars">
    </label>


    <nav class="navbar">
      <ul>
      <li><AnchorLink to="/#home" title="home"></AnchorLink></li>
      <li><AnchorLink to="/#about" title="about"></AnchorLink></li>
      <li><AnchorLink to="/#reviews" title="reviews"></AnchorLink></li>
      <li><AnchorLink to="/#contact" title="contact"></AnchorLink></li>
      </ul>
    </nav>

  </header>

)

export default Navigation