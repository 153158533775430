import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";

const HeroSection = (props) => ( 
    <section class="home" id="home">

    <div class="content">
        <h3><span>Mobile</span> <span class="white">Cleaning</span><span> Services</span></h3>
        <p>We offer a variety of cleaning services that can fit any person's budget and needs</p>
        <AnchorLink to="#contact" title="Request a Quote" className="btn" />
    </div>

    <div class="image">
        <img src="images/hero-image.png" alt="hero-image" />
    </div>

</section>

)

export default HeroSection