import * as React from "react"
import { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

const ContactForm = () => {
    useEffect (() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out-back'
        }, [])
    })
    return (
        <section class="contact" id="contact">

            <div class="image"
            data-aos="zoom-in-right">
                <img src="images/contact-img-2.png" alt="contact" />
            </div>

            <form action="https://getform.io/f/a4e86dad-2623-4e43-ac42-fd2d7f9e6b02" method="POST"
            data-aos="zoom-in-left">

                <h1 class="heading">contact us</h1>

                <div class="inputBox">
                    <input placeholder=" Your Name *" type="text" name="name" required />
                </div>

                <div class="inputBox">
                    <input placeholder=" Your Email *" type="email" name="email" required />
                </div>

                <div class="inputBox">
                    <input placeholder=" Your Phone Number *" type="text" name="name" required />
                </div>

                <div class="inputBox">
                    
                    <textarea placeholder=" Message *" name="message" id="" cols="30" rows="10"></textarea>
                </div>

                <button type="submit" className="btn">Send Message</button>

            </form>

        </section>
    )
}

export default ContactForm