import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Footer = () => {
    return (
    <div class="footer">

    <div class="box-container">

        <div class="box" id="about">
            <h3>about us</h3>
            <p>MSC offers professional cleaning services for homes and commercial properties.<br />
             Our service portfolio offers many services, including carpet cleaning, upholstery cleaning, area rug cleaning and more. </p>
        </div>

        <div class="box">
            <h3>quick links</h3>
            <AnchorLink to="#home" title="home" />
            <AnchorLink to="#about" title="about" />
            <AnchorLink to="#review" title="review" />
            <AnchorLink to="#contact" title="contact" />
        </div>

        <div class="box">
            <h3>follow us</h3>
            <a href="/">facebook</a>
            <a href="/">instagram</a>
            <a href="/">twitter</a>
        </div>

        <div class="box">
            <h3>contact info</h3>
            <div class="info">
                <i class="fas fa-phone"></i>
                <p> +357 95 530 922</p>
            </div>
            <div class="info">
                <i class="fas fa-envelope"></i>
                <p> sales@mcs.cy </p>
            </div>
            <div class="info">
                <i class="fas fa-map-marker-alt"></i>
                <p> Paralimni, Famagusta </p>
            </div>
        </div>

    </div>

    <h1 class="credit">Designed by  <a href="https://aftermind.xyz">aftermind.project </a></h1>

</div>
)
}

export default Footer